(function ($, Drupal) {
  var contentBlockCountrySelect = {
    locale: false,
    setup: function (context) {
      var $countrySelect = $('.js-content-block-country-select--v1', context);
      var $selectBoxMenu = $();

      $countrySelect.selectBox();
      $selectBoxMenu = $('a.js-content-block-country-select--v1', context);
      this.init($countrySelect, $selectBoxMenu);
    },
    init: function ($countrySelect, $selectBoxMenu) {
      var self = this;
      var countryId;
      var languageId;
      var $context = $();
      var isUnitedStatesStore = $.cookie('stores') === '0' && self.locale === 'us' || !$.cookie('stores') && self.locale === 'us';
      var isForceDisplay = $countrySelect.data('force-display') === true;

      if (!$countrySelect.length) {
        return;
      }
      $context = $countrySelect.parent();

      if (isUnitedStatesStore === true && isForceDisplay !== true) {
        $context.removeClass('js-content-block-signup--has-country-select');
        self.remove($context);
        return;
      }

      $countrySelect.on('change', function () {
        countryId = $(this).val();
        if (countryId === '') {
          $(document).trigger('contentBlock.countrySelectChangeEmpty', [$context]);
        }

        languageId = $($(this).find('option:selected')[0]).attr('data-language-id');
        $(document).trigger('contentBlock.countrySelectChange', [countryId, languageId, $context]);
      });

      $(window).on('scroll', _.throttle(function () {
        if ($selectBoxMenu.hasClass('selectBox-menuShowing')) {
          $countrySelect.selectBox('destroy').selectBox();
        }
      }, 333));
    },
    remove: function (context) {
      var $countrySelect = $(context).find('.js-content-block-country-select--v1');

      if ($countrySelect.length < 1) {
        return;
      }
      $countrySelect.remove();
    }
  };

  Drupal.behaviors.contentBlockCountrySelectV1 = {
    attached: false,
    attach: function (context, settings) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.attached = true;
      contentBlockCountrySelect.locale = settings.fredericmalle.locale || false;
      contentBlockCountrySelect.setup(context);
    }
  };

  $(document).on('contentBlock.signupSuccess', function (e, context) {
    contentBlockCountrySelect.remove(context);
  });
})(jQuery, Drupal);
